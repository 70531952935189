import { createAsyncThunk } from "@reduxjs/toolkit";
import { showNotification } from "../utils/showNotification";
import axiosApi from "../utils/axiosApi";

export const getCitiesByCountry = createAsyncThunk("get/CitiesByCountry", async (id) => {
  try {
    const response = await axiosApi.get(`/city/${id}`);
    return response.data;
  } catch (error) {}
});

export const getCities = createAsyncThunk("get/Cities", async () => {
  try {
    const response = await axiosApi.get("city/all");
    return response.data;
  } catch (error) {
    console.log(error);
  }
});
export const postCity = createAsyncThunk("post/City", async (data, { dispatch }) => {
  try {
    await axiosApi.post("city", data);
    await dispatch(getCities());
    showNotification("success", "New city successfully added");
  } catch (error) {
    console.log(error);
  }
});
export const updateCity = createAsyncThunk("update/City", async ({ id, data }) => {
  try {
    await axiosApi.put(`city/${id}`, data);
  } catch (error) {
    console.log(error);
  }
});
export const deleteCity = createAsyncThunk("delete/City", async (id, { dispatch }) => {
  try {
    const response = await axiosApi.delete(`city/${id}`);
    if (response.status === 204) {
      showNotification("success", "City successfully deleted");
      await dispatch(getCities());
    }
    console.log(response.data);
  } catch (error) {
    console.log(error);
  }
});

export const getCountries = createAsyncThunk("get/Cuntries", async () => {
  try {
    const response = await axiosApi.get("country");

    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const postCountry = createAsyncThunk("post/Country", async (data, { dispatch }) => {
  try {
    const response = await axiosApi.post("country", data);
    if (response.status === 201) {
      showNotification("success", "New country successfully added");
      await dispatch(getCountries());
    }
  } catch (error) {
    console.log(error.respose.data);
    // showNotification("error", "New country successfully added");
  }
});
export const updateCountry = createAsyncThunk("update/Country", async () => {
  try {
  } catch (error) {
    console.log(error);
  }
});
export const deleteCountry = createAsyncThunk("delete/Country", async (id, { dispatch }) => {
  try {
    const response = await axiosApi.delete(`country/${id}`);
    if (response.status === 200) {
      showNotification("success", "Country successfully deleted");
      await dispatch(getCountries());
    }
  } catch (error) {
    console.log(error);
  }
});
