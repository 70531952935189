import { createSlice } from "@reduxjs/toolkit";
import { getCities, getCountries, getCitiesByCountry } from "../../api/locationApi";

const locationSlice = createSlice({
  name: "location",
  initialState: {
    cities: null,
    allCities: null,
    citiesLoading: false,
    countries: null,
    countriesLoading: false,
  },
  reducers: {},
  extraReducers: {
    [getCitiesByCountry.pending]: (state) => {
      state.citiesLoading = true;
    },
    [getCitiesByCountry.fulfilled]: (state, { payload }) => {
      state.cities = payload;
      state.citiesLoading = false;
    },
    [getCitiesByCountry.rejected]: (state) => {
      state.citiesLoading = false;
    },
    [getCities.pending]: (state) => {
      state.citiesLoading = true;
    },
    [getCities.fulfilled]: (state, { payload }) => {
      state.allCities = payload;
      state.citiesLoading = false;
    },
    [getCities.pending]: (state) => {
      state.citiesLoading = false;
    },
    [getCountries.pending]: (state) => {
      state.countriesLoading = true;
    },
    [getCountries.fulfilled]: (state, { payload }) => {
      state.countries = payload;
      state.countriesLoading = false;
    },
    [getCountries.rejected]: (state) => {
      state.countriesLoading = false;
    },
  },
});

export default locationSlice.reducer;

export const selectCities = (state) => state.allCities;
export const selectCitiesLoading = (state) => state.citiesLoading;
