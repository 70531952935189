import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { logOutUser, selectCurrentToken, selectCurrentUser } from "../../store/slices/authSlice";
import { Button } from "antd";

const Navigation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(selectCurrentToken);
  const user = useSelector(selectCurrentUser);
  const handleLogout = async () => {
    await dispatch(logOutUser());
    navigate("/login");
  };
  return (
    <header className="header">
      <div className="header__navigation">
        <div className="navigation__menu">
          <NavLink to="/statistic" className={({ isActive }) => (isActive ? "nav__link active" : "nav__link")}>
            Statistic
          </NavLink>
          <NavLink to="/" className={({ isActive }) => (isActive ? "nav__link active" : "nav__link")}>
            Schools
          </NavLink>
          <NavLink to="add-school" className={({ isActive }) => (isActive ? "nav__link active" : "nav__link")}>
            Add school
          </NavLink>
          <NavLink to="/country" className={({ isActive }) => (isActive ? "nav__link active" : "nav__link")}>
            Country
          </NavLink>
          <NavLink to="/city" className={({ isActive }) => (isActive ? "nav__link active" : "nav__link")}>
            City
          </NavLink>
        </div>
        <div className="profile__menu">
          {token && (
            <>
              <p className="profile__menu__user-name">{user}</p>
              <Button type="primary" onClick={handleLogout}>
                Logout
              </Button>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Navigation;
