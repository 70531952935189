import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteCountry, getCountries, postCountry } from "../../api/locationApi";
import { Controller, useForm } from "react-hook-form";
import { LoadingOutlined, DeleteTwoTone } from "@ant-design/icons";
import { Button, Input, Modal, Popconfirm, Spin, Table, Tooltip, Typography } from "antd";
import "./style.scss";

const { Text } = Typography;
const { Title } = Typography;
const Country = () => {
  const dispatch = useDispatch();
  const { countries, countriesLoading } = useSelector((state) => state.location);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleToggleModal = () => {
    setIsOpenModal((prev) => !prev);
  };

  const columns = [
    {
      title: "Country name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "",
      dataIndex: "_id",
      key: "_id",
      render: (_, record) => (
        <div className="table-action">
          <Tooltip title="Delete country" color="geekblue">
            <Popconfirm
              title="Delete Country"
              description="Are you sure to delete this country?"
              okText="Yes"
              cancelText="Cancel"
              align="left"
              onConfirm={() => dispatch(deleteCountry(record._id))}
            >
              <DeleteTwoTone style={{ fontSize: 22 }} />
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleAddCountry = (data) => {
    dispatch(postCountry(data));
    reset();
    setIsOpenModal(false);
  };

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);

  return (
    <div className="container country">
      {countriesLoading ? (
        <Spin
          className="my__spinner"
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 85,
              }}
            />
          }
        />
      ) : (
        <div className="countries">
          <Button onClick={handleToggleModal} size="large" type="primary">
            Add Country
          </Button>
          <Modal open={isOpenModal} onCancel={handleToggleModal} footer={null}>
            <form className="add_country_form" onSubmit={handleSubmit(handleAddCountry)}>
              <Controller
                control={control}
                name="name"
                rules={{
                  required: "Country name is required",
                }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Title className="title" level={2}>
                      Add country
                    </Title>
                    <Input
                      value={value}
                      onChange={onChange}
                      status={errors.name ? "error" : ""}
                      className="default-input"
                      placeholder="Country name"
                      size="large"
                      style={{ height: "55px" }}
                    />
                  </>
                )}
              />
              {errors.name && (
                <Text type="danger" className="error__validation">
                  {errors.name.message}
                </Text>
              )}
              <Button type="primary" htmlType="submit" size="large" className="add__btn">
                Add
              </Button>
            </form>
          </Modal>

          <div className="table__wrapper">
            <Table dataSource={countries} columns={columns} pagination={null} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Country;
