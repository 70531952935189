import React, { useEffect, useMemo, useState } from "react";
import { Button, Input, Typography, Checkbox, Select, Divider } from "antd";
import { Controller, useForm } from "react-hook-form";
import { ReactComponent as MoveIcon } from "../../assets/move-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { addNewSchool } from "../../api/schoolApi";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { CURRENCIES } from "../../utils/constants";
import { getCitiesByCountry, getCountries } from "../../api/locationApi";
import "./style.scss";

const { Title } = Typography;
const { Text } = Typography;

const AddSchool = () => {
  const dispatch = useDispatch();
  const [descriptions, setDescriptions] = useState([""]);
  const { countries, countriesLoading, cities } = useSelector((state) => state.location);

  const [draggedItem, setDraggedItem] = useState(null);

  const handleDragStart = (index) => {
    setDraggedItem(index);
  };

  const handleDragOver = (index) => {
    if (draggedItem === null || draggedItem === index) {
      return;
    }

    const updatedItems = [...descriptions];
    const draggedString = updatedItems[draggedItem];
    updatedItems.splice(draggedItem, 1);
    updatedItems.splice(index, 0, draggedString);

    setDescriptions(updatedItems);
    setDraggedItem(index);
  };

  const handleDragEnd = () => {
    setDraggedItem(null);
  };

  const handleChangeDescription = (index, value) => {
    const updatedDescriptions = [...descriptions];
    updatedDescriptions[index] = value;
    setDescriptions(updatedDescriptions);
  };

  const addDescriptionFiled = () => {
    setDescriptions([...descriptions, ""]);
  };

  const handleRemoveDescription = (index) => {
    setDescriptions(() => {
      return descriptions.filter((_, i) => i !== index);
    });
  };

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const handleChangeCountry = (countryId) => {
    dispatch(getCitiesByCountry(countryId));
  };

  const handleAddSchool = async (data) => {
    const updatedSchool = Object.assign(data, { description: descriptions });
    dispatch(addNewSchool(updatedSchool));
    setDescriptions([""]);
    reset();
  };

  const countriesItems = useMemo(
    () =>
      countries?.map((country) => ({
        label: country.name,
        value: country._id,
      })) || [],
    [countries]
  );

  const citiesItems = useMemo(
    () =>
      cities?.map((city) => ({
        label: city.name,
        value: city._id,
      })) || [],
    [cities]
  );

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);

  return (
    <div className="container create-school">
      <Title className="title" level={2}>
        Add new School
      </Title>
      <form onSubmit={handleSubmit(handleAddSchool)}>
        <Controller
          control={control}
          name="name"
          rules={{
            required: "School name is required",
          }}
          render={({ field: { onChange, value } }) => (
            <>
              <Text>Name</Text>
              <Input
                value={value}
                onChange={onChange}
                status={errors.name ? "error" : ""}
                className="default-input"
                placeholder="Name"
                size="large"
                style={{ height: "55px" }}
              />
            </>
          )}
        />

        {errors.name && (
          <Text type="danger" className="error__validation">
            {errors.name.message}
          </Text>
        )}

        {!countriesLoading && (
          <Controller
            control={control}
            name="country"
            render={({ field: { onChange, value } }) => (
              <>
                <Text>Country</Text>
                <Select
                  size="large"
                  value={value}
                  placeholder="School country"
                  onChange={(countryId) => {
                    setValue("city", null);
                    onChange(countryId);
                    handleChangeCountry(countryId);
                  }}
                  options={countriesItems}
                  style={{ height: "55px" }}
                />
              </>
            )}
          />
        )}

        {citiesItems.length > 0 && (
          <Controller
            control={control}
            name="city"
            render={({ field: { onChange, value } }) => (
              <>
                <Text>City</Text>
                <Select
                  size="large"
                  value={value}
                  placeholder="School city"
                  onChange={onChange}
                  options={citiesItems}
                  style={{ height: "55px" }}
                />
              </>
            )}
          />
        )}

        <Controller
          control={control}
          name="curriculum"
          rules={{
            required: "Curriculum of  school  is required",
          }}
          render={({ field: { onChange, value } }) => (
            <>
              <Text>Curriculum</Text>
              <Input
                value={value}
                onChange={onChange}
                status={errors.curriculum ? "error" : ""}
                className="default-input"
                placeholder="Curriculum of  school"
                size="large"
                style={{ height: "55px" }}
              />
            </>
          )}
        />
        {errors.curriculum && (
          <Text type="danger" className="error__validation">
            {errors.curriculum.message}
          </Text>
        )}
        {/*  */}

        <Controller
          control={control}
          name="yearsOfStudy"
          rules={{
            required: "Years of study is required",
          }}
          render={({ field: { onChange, value } }) => (
            <>
              <Text>Year of study</Text>
              <Input
                value={value}
                onChange={onChange}
                status={errors.yearsOfStudy ? "error" : ""}
                className="default-input"
                placeholder="Year of study"
                size="large"
                style={{ height: "55px" }}
              />
            </>
          )}
        />
        {errors.yearsOfStudy && (
          <Text type="danger" className="error__validation">
            {errors.yearsOfStudy.message}
          </Text>
        )}

        {/*  */}
        <Controller
          control={control}
          name="area"
          rules={{
            required: "Address of school is required",
          }}
          render={({ field: { onChange, value } }) => (
            <>
              <Text>Address</Text>
              <Input
                value={value}
                onChange={onChange}
                status={errors.area ? "error" : ""}
                className="default-input"
                placeholder="Address of school"
                size="large"
                style={{ height: "55px" }}
              />
            </>
          )}
        />

        <Controller
          control={control}
          name="currency"
          rules={{
            required: "Currency are required",
          }}
          render={({ field: { onChange, value } }) => (
            <>
              <Text>Currency</Text>
              <Select
                size="large"
                value={value}
                placeholder="Currency"
                onChange={onChange}
                options={CURRENCIES}
                style={{ height: "55px" }}
              />
            </>
          )}
        />

        {errors.currency && (
          <Text type="danger" className="error__validation">
            {errors.currency.message}
          </Text>
        )}

        {/*  */}
        <Controller
          control={control}
          name="budget"
          rules={{
            required: "Budget of school is required",
          }}
          render={({ field: { onChange, value } }) => (
            <>
              <Text>Budget</Text>
              <Input
                type="number"
                value={value}
                onChange={onChange}
                status={errors.budget ? "error" : ""}
                className="default-input"
                placeholder="Budget of school per year"
                size="large"
                style={{ height: "55px" }}
              />
            </>
          )}
        />
        {errors.budget && (
          <Text type="danger" className="error__validation">
            {errors.budget.message}
          </Text>
        )}
        {/*  */}
        <Controller
          control={control}
          name="nationality"
          rules={{
            required: "Nationality of schools are required",
          }}
          render={({ field: { onChange, value } }) => (
            <>
              <Text>Nationality</Text>
              <Input
                value={value}
                onChange={onChange}
                status={errors.nationality ? "error" : ""}
                className="default-input"
                placeholder="Nationality"
                size="large"
                style={{ height: "55px" }}
              />
            </>
          )}
        />
        {errors.nationality && (
          <Text type="danger" className="error__validation">
            {errors.nationality.message}
          </Text>
        )}
        {/*  */}

        <Controller
          control={control}
          defaultValue={null}
          name="schoolUrl"
          render={({ field: { onChange, value } }) => (
            <>
              <Typography>Website URL</Typography>
              <Input
                value={value}
                onChange={(e) => {
                  const newValue = e.target.value.trim() === "" ? null : e.target.value;
                  onChange(newValue);
                }}
                status={errors.nationality ? "error" : ""}
                className="default-input"
                placeholder="School Website URL"
                size="large"
                style={{ height: "55px" }}
              />
            </>
          )}
        />
        <Divider dashed={true} />

        <>
          <Text>Description:</Text>
          {descriptions.map((description, idx) => (
            <div
              key={idx}
              className="description__item"
              onDragOver={() => handleDragOver(idx)}
              onDragEnd={handleDragEnd}
              style={{ backgroundColor: draggedItem === idx ? "#c3c3c3" : "transparent" }}
            >
              <div
                className="draggble__item"
                draggable
                onDragStart={() => handleDragStart(idx)}
                style={{
                  cursor: "move",
                  userSelect: "none",
                }}
              >
                <MoveIcon style={{ fill: draggedItem === idx ? "blue" : "#000000" }} />
              </div>
              <Input
                value={description}
                onChange={(e) => handleChangeDescription(idx, e.target.value)}
                size="large"
                className="description__input"
              />
              <button type="button" onClick={() => handleRemoveDescription(idx)} className="delete-description_btn">
                <DeleteOutlined style={{ fontSize: "20px" }} />
              </button>
            </div>
          ))}
        </>
        <div className="add-description__wrapper">
          <Button type="button" onClick={addDescriptionFiled} className="add-description__button">
            Add description field
            <PlusOutlined size="large" />
          </Button>
        </div>
        <div className="rate-container">
          <Controller
            control={control}
            name="recommended"
            defaultValue={false}
            render={({ field: { onChange, value } }) => (
              <Checkbox onChange={onChange} checked={value}>
                Parents Preferred School
              </Checkbox>
            )}
          />
        </div>

        <Button type="primary" htmlType="submit" size="large" className="add-school__btn">
          Add
        </Button>
      </form>
    </div>
  );
};

export default AddSchool;
