import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteCity, getCities, getCountries, postCity } from "../../api/locationApi";
import { Controller, useForm } from "react-hook-form";
import { DeleteTwoTone, LoadingOutlined } from "@ant-design/icons";
import { Button, Modal, Spin, Table, Typography, Input, Select, Tooltip, Popconfirm } from "antd";
import "./style.scss";

const { Text } = Typography;

const City = () => {
  const { allCities, citiesLoading, countries, countriesLoading } = useSelector((state) => state.location);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();

  const handleToggleModal = () => {
    setIsOpenModal((prev) => !prev);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setValue("name", null);
    setValue("country", null);
    clearErrors("country", null);
    clearErrors("name", "");
  };
  const handleAddCity = (data) => {
    dispatch(postCity(data));
    reset();
    handleCloseModal();
  };

  const columns = [
    {
      title: "City name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => {
        return a.name?.localeCompare(b?.name);
      },
    },
    {
      title: "Country",
      dataIndex: "name",
      key: "name",
      render: (_, record) => <span>{record.country?.name}</span>,
      sorter: (a, b) => {
        return a.country?.name?.localeCompare(b.country?.name);
      },
    },
    {
      title: "",
      dataIndex: "_id",
      key: "_id",
      render: (_, record) => (
        <div className="table-action">
          <Tooltip title="Delete city" color="geekblue">
            <Popconfirm
              title="Delete city"
              description={`"Are you sure to delete ${record.name} city ?"`}
              okText="Yes"
              cancelText="Cancel"
              align="left"
              onConfirm={() => dispatch(deleteCity(record._id))}
            >
              <DeleteTwoTone style={{ fontSize: 22 }} />
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  const countriesItems = useMemo(
    () =>
      countries?.map((country) => ({
        label: country?.name,
        value: country._id,
      })) || [],
    [countries]
  );

  useEffect(() => {
    dispatch(getCities());
    dispatch(getCountries());
  }, [dispatch]);

  return (
    <div className="container city">
      {citiesLoading ? (
        <Spin
          className="my__spinner"
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 85,
              }}
            />
          }
        />
      ) : (
        <div className="cities">
          <Button size="large" type="primary" onClick={handleToggleModal} disabled={citiesLoading}>
            Add City
          </Button>
          <Modal open={isOpenModal} onCancel={handleCloseModal} footer={null} title="Add City form">
            <form className="add_city_form" onSubmit={handleSubmit(handleAddCity)}>
              <Controller
                control={control}
                name="name"
                rules={{
                  required: "City  is required",
                }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Text>City</Text>
                    <Input
                      value={value}
                      onChange={onChange}
                      status={errors.name ? "error" : ""}
                      className="default-input"
                      placeholder="City name"
                      size="large"
                      style={{ height: "55px" }}
                    />
                  </>
                )}
              />
              {errors.name && (
                <Text type="danger" className="error__validation">
                  {errors.name.message}
                </Text>
              )}
              {!countriesLoading && (
                <Controller
                  control={control}
                  name="country"
                  rules={{
                    required: "City  is required",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Text>Country</Text>
                      <Select
                        size="large"
                        value={value}
                        placeholder="Choose country to assign for city"
                        onChange={onChange}
                        options={countriesItems}
                        style={{ height: "55px" }}
                      />
                    </>
                  )}
                />
              )}
              {errors.country && (
                <Text type="danger" className="error__validation">
                  {errors.country.message}
                </Text>
              )}
              <Button type="primary" htmlType="submit" size="large" className="add__btn">
                Add
              </Button>
            </form>
          </Modal>
          <div className="table__wrapper">
            <Table dataSource={allCities} columns={columns} pagination={null} />
          </div>
        </div>
      )}
    </div>
  );
};

export default City;
