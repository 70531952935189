import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import schoolReducer from "./slices/schoolSlice";
import statisticReducer from "./slices/statisticSlice";
import locationReducer from "./slices/locationSlice";
export const store = configureStore({
  reducer: {
    auth: authReducer,
    schools: schoolReducer,
    statistic: statisticReducer,
    location: locationReducer,
  },

  devTools: true,
});
