import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosApi from "../utils/axiosApi";
import { showNotification } from "../utils/showNotification";
import { saveToLocalStorage } from "../utils/localStorage";

export const loginUser = createAsyncThunk("auth/loginUser", async (data) => {
  try {
    const response = await axiosApi.post("user/login", data);
    saveToLocalStorage("accessToken", response.data.user.token);
    return response.data.user;
  } catch (error) {
    showNotification("error", error.response.data.message);
  }
});
