import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosApi from "../utils/axiosApi";
import { showNotification } from "../utils/showNotification";

export const addNewSchool = createAsyncThunk("add/school", async (data) => {
  try {
    console.log(data);
    await axiosApi.post("school", data);
    showNotification("success", "New school successfully added");
  } catch (error) {
    console.log(error);
  }
});

export const getSchools = createAsyncThunk("get/school", async () => {
  try {
    const response = await axiosApi.get("school");
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const getSchoolByID = createAsyncThunk("get/schoolByID", async (id) => {
  try {
    const response = await axiosApi.get(`school/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const deleteSchool = createAsyncThunk("delete/schoolByID", async (id) => {
  try {
    await axiosApi.delete(`school/${id}`);
  } catch (error) {
    console.log(error);
  }
});

export const updateSchool = createAsyncThunk("add/school", async ({ id, data }) => {
  try {
    await axiosApi.put(`school/${id}`, data);

    showNotification("success", "School successfully updated");
  } catch (error) {
    console.log(error);
  }
});
